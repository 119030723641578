import Loadable from 'react-loadable';
import { asTemplate } from '@apps/firsthomecoach/src/components/layout/with-layout';
import { QUESTION } from '@apps/firsthomecoach/src/helpers/navigation';
import { Loader } from '@apps/firsthomecoach/src/components/with-loader';
import { INITIAL_SAVING_TARGET_QUESTION_SLUG } from '@apps/firsthomecoach/src/helpers/persona-type/utils';

export const Question = Loadable({
  loader: () =>
    import(/* webpackChunkName: "question"*/ '@apps/firsthomecoach/src/pages/question').then(module => module.Question),
  loading: Loader
});

const SavingTargetQuestion = Loadable({
  loader: () =>
    import(/* webpackChunkName: "brighter-future-saving-target" */ '../pages/question/saving-target').then(
      module => module.SavingTargetQuestion
    ),
  loading: Loader
});

export const questions = [
  {
    to: `/checklist/brighter-future/${INITIAL_SAVING_TARGET_QUESTION_SLUG}`,
    component: asTemplate(QUESTION)(SavingTargetQuestion)
  },
  {
    to: '/(checklist|account)/:activity/:slug/:identifier?',
    component: asTemplate(QUESTION)(Question)
  }
];

import Loadable from 'react-loadable';
import { TLoader } from '@apps/firsthomecoach/src/components/template';
import { FeaturedTools } from './featured-tools';
import { SplitBlock } from './split-block';

const DigitalCoach = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-digital-coach"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/digital-coach'
    ).then(module => module.DigitalCoach),
  loading: TLoader
});

const DashboardContent = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-content"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/content').then(
      module => module.DashboardContent
    ),
  loading: TLoader
});

const DashboardAdverts = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-adverts"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/adverts').then(
      module => module.DashboardAdverts
    ),
  loading: TLoader
});

const InPageMessageWidget = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-in-page-message"*/ '@apps/firsthomecoach/src/components/in-page-message/widget'
    ).then(module => module.InPageMessageWidget),
  loading: TLoader
});

const DashboardContentRepeater = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-vm-content-repeater"*/ '@apps/firsthomecoach/src/widgets/repeater/content-repeater'
    ).then(module => module.DashboardContentRepeater),
  loading: TLoader
});

export const dashboardWidgets = {
  featuredTools: FeaturedTools,
  splitBlock: SplitBlock,
  adverts: DashboardAdverts,
  content: DashboardContent,
  digitalCoach: DigitalCoach,
  inPageMessage: InPageMessageWidget,
  repeater: DashboardContentRepeater
};

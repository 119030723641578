import React from 'react';
import classnames from 'classnames';
import { compose, withHandlers, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import styles from '../styles/components/card.module.scss';
import { openLink } from '../helpers/utils';
import { Spinner } from './with-loader';

const withAccessibility = compose(
  withProps(({ onClick, to, isExternal = false, history }) => {
    let handler, role;

    if (onClick) {
      role = 'button';
      handler = onClick;
    }

    if (to) {
      role = 'link';
      handler = () => openLink(to, history, isExternal);
    }

    return {
      role,
      tabIndex: handler && 0,
      onClick: handler
    };
  }),
  withHandlers({
    onKeyDown:
      ({ role, onClick }) =>
      event => {
        const keyCodes = [];

        switch (role) {
          case 'button':
            keyCodes.push(13, 32);
            break;
          default:
            keyCodes.push(13);
        }

        if (keyCodes.some(code => code === event.which)) {
          onClick();

          event.preventDefault();
        }
      }
  })
);

const withContainer = Component =>
  React.memo(({ role, tabIndex, className, style, loading, onClick, onKeyDown, ...props }) => (
    <article
      role={role}
      tabIndex={tabIndex}
      className={classnames(styles.container, className)}
      style={style}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {loading ? <Spinner className={styles.loading} /> : <Component {...props} />}
    </article>
  ));

export const renderAsCard = compose(withRouter, withAccessibility, withContainer);

import { compose } from 'redux';
import { branch, componentFromProp, mapProps, renderNothing } from 'recompose';
import { withDisplayCondition } from '../../../helpers/hocs/with-display-condition';
import { createPageIdentifier } from '../../../helpers/reducers/application';
import { withInitialWidgetLoadingLifecycle } from '../../../components/layout/content/with-native-loading-state';

export const DashboardWidget = compose(
  mapProps(({ name, widgets = {}, loadingState, ...props }) => {
    const dashboardWidgets = { ...widgets };
    const pageIdentifier = createPageIdentifier(window.location);

    return {
      ...props,
      component: dashboardWidgets[name] ?? renderNothing,
      widgetName: name,
      pageIdentifier
    };
  }),
  withDisplayCondition,
  branch(({ component }) => component, withInitialWidgetLoadingLifecycle)
)(componentFromProp('component'));

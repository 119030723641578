import React from 'react';
import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { Column } from '@apps/firsthomecoach/src/components/layout/grid';
import { withWidgetController } from '@apps/firsthomecoach/src/pages/dashboard/components/with-controller';
import { getArticles } from '@apps/firsthomecoach/src/store/reducers/content';
import { withGridContext } from '@apps/firsthomecoach/src/pages/dashboard/components/with-grid';
import { withWidgetTracking } from '@apps/firsthomecoach/src/pages/dashboard/hocs/with-widget-tracking';

import styles from '../../../../styles/widgets/split-block.module.scss';
import { SplitArticle } from './components/article';

const Component = React.memo(
  ({ sideA: { body: bodyA, imageUrl: imageA }, sideB: { body: bodyB, imageUrl: imageB } }) => (
    <Column className={styles.grid} edge>
      <div className={styles.wrapper}>
        <SplitArticle className={styles['side-a']} body={bodyA} image={imageA} />
        <SplitArticle className={styles['side-b']} body={bodyB} image={imageB} />
      </div>
    </Column>
  )
);

const mapStateToProps = (state, { slugList }) => ({
  sideA: state.content.contentPages[slugList.sideA],
  sideB: state.content.contentPages[slugList.sideB]
});

export const SplitBlock = compose(
  withWidgetController({ className: styles.container }),
  withGridContext,
  withWidgetTracking,
  connect(mapStateToProps, { getArticles }),
  lifecycle({
    async componentDidMount() {
      const { getArticles, slugList } = this.props;

      const slugs = Object.values(slugList).join(',');
      await getArticles({ slugs });
    }
  }),
  branch(({ sideA, sideB }) => !sideA || !sideB, renderNothing)
)(Component);

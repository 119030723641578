import { UserService } from '../../services/user-service';

export const getMarketingListsByAccountType = (lists = []) => {
  return lists.filter(list => {
    if (UserService.hasUser() || UserService.hasAccount()) {
      return list;
    }

    return list.nonRegisteredEnabled;
  });
};

export const parseClientSchemaIdParameter = clientSchemeId => {
  return clientSchemeId?.toLowerCase() === 'leaver' ? null : clientSchemeId;
};

import React from 'react';
import { Column, Grid } from '../../grid';
import { BlogBackButton, Body, Category, Heading, Image, SubHeading } from '../components';
import { getDateFromNow, getMinutesRead } from '../../../../helpers/articles/article';
import styles from '../../../../styles/components/page-header/vm-page-header.module.scss';

const Meta = React.memo(({ articleBody, modifiedDate }) => (
  <ul className={styles.meta}>
    <li className={styles['meta-item']}>{getMinutesRead(articleBody)}</li>
    <li className={styles['meta-item']}>Updated {getDateFromNow(modifiedDate)}</li>
  </ul>
));

export const PageHeader = React.memo(
  ({ type, heading, subheading, image, alt, category, modifiedDate, articleBody, showBackLink, goToBlog }) => (
    <header className={styles.container}>
      <Grid>
        <Column edge>
          <div className={styles.wrapper}>
            <Grid as={Body} className={styles.body}>
              <Column className={styles.words}>
                {category && <Category className={styles['category-body']}>{category}</Category>}
                {type === 'blog' && showBackLink && <BlogBackButton onClick={goToBlog} />}
                {heading && <Heading>{heading}</Heading>}
                {subheading && <SubHeading>{subheading}</SubHeading>}
                {(modifiedDate || articleBody) && <Meta modifiedDate={modifiedDate} articleBody={articleBody} />}
              </Column>
            </Grid>
            {image && (
              <Image src={image} alt={alt}>
                {category && <Category className={styles['category-image']}>{category}</Category>}
              </Image>
            )}
          </div>
        </Column>
      </Grid>
    </header>
  )
);

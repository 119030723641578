import { compose, defaultProps } from 'recompose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { withLayout } from './with-layout';
import { withTitleMapper } from './with-title-mapper';

export const withWidgetController = (props = {}) =>
  compose(
    defaultProps(props),
    withPropsOnChange(['grid', 'edge'], ({ grid, edge }) => ({
      // This context will be accessible as a prop for each Widget Component
      // which is consumed this HOC to implement widget layout.
      // So each widget would know the environment in which it was rendered.
      layoutContext: {
        grid: !!grid,
        edge: !!edge
      }
    })),
    withTitleMapper,
    withLayout
  );

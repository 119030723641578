import React from 'react';
import classnames from 'classnames';
import { Column, Grid } from '@apps/firsthomecoach/src/components/layout/grid';
import { Image } from '@apps/firsthomecoach/src/components/images/image';
import { TBody } from '@apps/firsthomecoach/src/components/template';
import styles from '../../../../../styles/widgets/split-block-article.module.scss';

export const SplitArticle = ({ className, body, image }) => (
  <div className={classnames(styles.container, className)}>
    <Grid className={styles.grid}>
      <Column className={styles.article}>
        {image && (
          <div className={styles.image}>
            <Image src={image} cover fluid />
          </div>
        )}
        <TBody html={body} />
      </Column>
    </Grid>
  </div>
);

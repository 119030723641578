import React from 'react';
import { Column } from '../../../components/layout/grid';

export const withGridContext =
  Component =>
  ({ layoutContext, ...props }) => {
    return layoutContext.grid ? (
      <Column>
        <Component {...props} />
      </Column>
    ) : (
      <Component {...props} />
    );
  };

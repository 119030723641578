import classnames from 'classnames';
import React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import { sanitizeString } from '../helpers/sanitize';
import { withDisplayCondition } from '../helpers/hocs/with-display-condition';
import styles from '../styles/components/editorial-card.module.scss';
import { useFavorite } from '../helpers/status-data/use-favorite';
import { renderAsCard } from './card';
import { AffiliateIcon } from './icons/affiliate-icon';
import { AspectRatioImage } from './images/aspect-ratio-image';
import { ButtonWithIcon } from './buttons/button-with-icon';

const CardIcon = React.memo(({ icon, showSaveButton, type, slug, id, title }) => {
  const { iconName, handleAction } = useFavorite({ ownKey: slug, type, slug, id, title });

  if (showSaveButton) {
    return (
      <ButtonWithIcon className={styles.action} onClick={handleAction} icon={false}>
        <AffiliateIcon name={iconName} />
      </ButtonWithIcon>
    );
  }

  return <>{icon && <AffiliateIcon name={icon} className={styles.action} />}</>;
});

const Card = React.memo(
  ({
    imageUrl,
    title,
    titleAs: Title = 'h4',
    children,
    icon = 'card-action',
    footerLabel,
    showSaveButton,
    type,
    slug,
    id
  }) => (
    <>
      {imageUrl && <AspectRatioImage className={styles.image} src={imageUrl} title={title} aspectRatio='16x9' />}
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {title && (
            <hgroup className={styles.header}>
              <Title className={styles.title}>{sanitizeString(title)}</Title>
            </hgroup>
          )}
          {children && <section className={styles.body}>{children}</section>}
          {footerLabel && (
            <footer className={styles.footer}>
              <span className={styles.label}>{footerLabel}</span>
              <CardIcon icon={icon} showSaveButton={showSaveButton} type={type} slug={slug} title={title} id={id} />
            </footer>
          )}
        </div>
      </div>
    </>
  )
);

export const EditorialCard = compose(
  withDisplayCondition,
  withPropsOnChange(['className'], ({ className, cardFooterLabel, readTime }) => ({
    className: classnames(styles.container, className),
    footerLabel: cardFooterLabel || readTime
  })),
  renderAsCard
)(Card);

import React, { useLayoutEffect } from 'react';
import { Animation } from '@apps/firsthomecoach/src/components/layout/animation';
import styles from '@apps/firsthomecoach/src/styles/components/application.module.scss';
import { ApplicationFooter } from '@apps/firsthomecoach/src/components/layout/application-footer';
import { ApplicationHeader } from '@apps/firsthomecoach/src/components/layout/application-header';
import { ConfigService } from '@apps/firsthomecoach/src/services/config-service';

export const Application = ({ children }) => {
  useLayoutEffect(() => {
    const { lottie } = ConfigService.get('CHECKPOINT', {});

    if (lottie) {
      Animation.preload();
    }
  }, []);

  return (
    <>
      <ApplicationHeader className={styles.header} />
      <main className={styles.content}>{children}</main>
      <ApplicationFooter className={styles.footer} />
    </>
  );
};

import React from 'react';
import compose from 'recompose/compose';
import styles from '../../styles/components/toggle-switch.module.scss';
import { Input } from './checkbox/checkbox';
import { withAnchor, withContainer, withExplainer, withLabel } from './input-field';

const Component = React.memo(({ id, value = false, className, ...props }) => (
  <div className={className}>
    <Input id={id} checked={value} className={styles.input} {...props} />
    {/*The disadvantage of a very custom implementation.*/}
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor={id} className={styles.body} />
  </div>
));

export const ToggleSwitch = withAnchor(Component);
export const InputToggleSwitch = compose(
  withAnchor,
  withContainer,
  withExplainer,
  withLabel({ className: styles.label, layout: styles.container })
)(Component);

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { branch, compose, withHandlers } from 'recompose';
import { extraPathnameSlash } from '../../helpers/regex';
import { UserService } from '../../services/user-service';
import { getUser } from '../../store/reducers/account/actions';
import { createShadowUser } from '../../store/reducers/account/shadow-user-actions';
import { withLoader, withLoadingHandlers } from '../with-loader';
import { withEmbeddedApp } from './with-embedded-app';
import { withDefaultApp } from './with-default-app';
import { withEmbeddedContext } from './with-embedded-context';
import { withAutoLogoutListeners } from './with-auto-logout';

export const withApp = compose(
  connect(null, {
    getUser,
    createShadowUser
  }),
  withEmbeddedContext,
  withLoadingHandlers(true),
  withRouter,
  withHandlers({
    replacePathnameExtraSlash:
      ({ history }) =>
      location => {
        const { pathname } = location;

        if (extraPathnameSlash.test(pathname)) {
          // replace extra slash at the end of pathname
          const cleanLocation = { ...location, pathname: pathname.replace(extraPathnameSlash, '') };

          history.replace(cleanLocation);
        }
      },
    processUserData:
      ({ getUser, createShadowUser }) =>
      async () => {
        if (UserService.hasAccount()) {
          return { introducerCode: null };
        }

        if (!(UserService.hasUser() || UserService.hasShadowUser())) {
          return createShadowUser();
        }

        return getUser();
      }
  }),
  withAutoLogoutListeners,
  branch(({ isEmbedded }) => isEmbedded, withEmbeddedApp),
  branch(({ isEmbedded }) => !isEmbedded, withDefaultApp),
  withLoader
);

import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { prettifyName } from '../../pages/question/helpers/prettify-name';

const INCREMENT_SEPARATOR = '-';

export const createSavingTargetId = (name, items) => prettifyName(name, items, INCREMENT_SEPARATOR);

// HINT: this can be later defined in the config file
export const INITIAL_SAVING_TARGET_QUESTION_SLUG = 'saving-for-what-not-in-list';

export const getPersonaType = ({ investorType, climatePersonaType, ...quiz }, answersToType, defaultType) => {
  if (!quiz || !answersToType) {
    return;
  }

  const mappedQuiz = mapResultsToFitRadioDataStructure(quiz);

  const answers = Object.values(mappedQuiz).sort().join('');

  const persona = answersToType[answers];

  if (persona) {
    return persona;
  }

  return defaultType;
};

export const mapResultsToFitRadioDataStructure = results =>
  ObjectShim.map(results, item => {
    return Object.entries(item).find(([_, { checked }]) => checked)[0];
  });

export const getPersonaAverageFromConfig = (configData, personaType) => {
  const { label: averageLabel, value: averageValue } = configData.filter(
    ({ personaType: cPersonaType }) => cPersonaType === personaType
  )[0];

  return { averageLabel, averageValue };
};

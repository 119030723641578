import React, { useCallback } from 'react';
import { compose } from 'recompose';
import useMountEffect from '@packages/hooks/core/use-mount-effect';
import IntersectionVisible from '../../../components/intersection-visibility';
import { withTrackingHandlers } from '../../../helpers/hocs/with-tracking-handlers';
import { TRACKING_STATUSES } from '../../../helpers/constants';
import styles from '../../../styles/widgets/widget-control.module.scss';

const withWidgetIntersection = Component =>
  React.memo(({ sendTrackingData, widgetName, ...props }) => {
    const onWidgetSeen = useCallback(() => {
      sendTrackingData(TRACKING_STATUSES.WIDGET_SEEN, {
        location: window.location.pathname,
        widgetName
      });
    }, [sendTrackingData, widgetName]);

    useMountEffect(() => {
      sendTrackingData(TRACKING_STATUSES.WIDGET_LOADED, {
        location: window.location.pathname,
        widgetName
      });
    });

    return (
      <>
        <IntersectionVisible onShow={onWidgetSeen} className={styles.intersect} once />
        <Component {...props} />
      </>
    );
  });

export const withWidgetTracking = compose(withTrackingHandlers, withWidgetIntersection);

import React from 'react';
import styles from '../../styles/components/knowledge-base-topic.module.scss';
import { AffiliateIcon } from '../../components/icons/affiliate-icon';
import { ConfigService } from '../../services/config-service';
import { KnowledgeBaseItem } from './item';

export const KnowledgeBaseTopic = React.memo(({ category, subHeading, slug, topicFilter, posts = [] }) => {
  const {
    TEMPLATE: { topicIcons, list, statusLabels = {} }
  } = ConfigService.get('ARTICLES_HOME_PAGE', {});

  const items =
    topicFilter === 'All' ? posts : posts.filter(({ homebuying_stage }) => homebuying_stage?.includes(topicFilter));

  return (
    <>
      {items.length ? (
        <>
          <div className={styles.container}>
            <div className={styles.header}>
              {topicIcons.show && (
                <AffiliateIcon
                  name={`articles-section-${slug}`}
                  fallback={{ external: topicIcons.defaultIcon }}
                  className={styles.icon}
                />
              )}
              <div className={styles.label}>
                <h2 className={styles.heading}>{category}</h2>
                {subHeading && <p className={styles.subheading}>{subHeading}</p>}
              </div>
            </div>
          </div>
          <ul className={styles.menu}>
            {items.map(post => (
              <KnowledgeBaseItem
                key={post.id}
                item={post}
                showThumbnailImage={list?.showThumbnailImage}
                showStatusLabel={list?.showStatusLabel}
                showStatusIcon={list?.showStatusIcon}
                statusLabels={statusLabels}
              />
            ))}
          </ul>
        </>
      ) : null}
    </>
  );
});

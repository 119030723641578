import { combineReducers } from 'redux';
import { content } from '@apps/firsthomecoach/src/store/reducers/content';
import { faq } from '@apps/firsthomecoach/src/store/reducers/faq';
import { statusData } from '@apps/firsthomecoach/src/store/reducers/status-data';
import { digitalCoach } from '@apps/firsthomecoach/src/store/reducers/digital-coach';
import { navigation } from '@apps/firsthomecoach/src/store/reducers/navigation';
import { layout } from '@apps/firsthomecoach/src/store/reducers/layout';
import { modals } from '@apps/firsthomecoach/src/store/reducers/modals';
import { questions } from '@apps/firsthomecoach/src/store/reducers/questions';
import { userAttributes } from '@apps/firsthomecoach/src/store/reducers/user-attributes';
import { consentList } from '@apps/firsthomecoach/src/store/reducers/consent-list';
import { staticData } from '@apps/firsthomecoach/src/store/reducers/static-data';
import { checklist } from '@apps/firsthomecoach/src/store/reducers/checklist';
import { lottie } from '@apps/firsthomecoach/src/store/reducers/lottie';
import { marketingLists } from '@apps/firsthomecoach/src/store/reducers/marketing-lists';
import { account } from '@apps/firsthomecoach/src/store/reducers/account';
import { contentInteraction } from '@apps/firsthomecoach/src/store/reducers/content-interaction';
import { repeaters } from '@apps/firsthomecoach/src/store/reducers/repeaters';
import { application } from '@apps/firsthomecoach/src/store/reducers/application';

export const reducers = combineReducers({
  account,
  userAttributes,
  content,
  faq,
  statusData,
  digitalCoach,
  navigation,
  layout,
  modals,
  questions,
  consentList,
  staticData,
  checklist,
  lottie,
  marketingLists,
  contentInteraction,
  repeaters,
  application
});

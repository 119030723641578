import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { getFeaturedTools } from '@apps/firsthomecoach/src/store/reducers/content';
import { Component as BaseArticles } from '@apps/firsthomecoach/src/pages/dashboard/widgets/content/component';
import { withWidgetController } from '@apps/firsthomecoach/src/pages/dashboard/components/with-controller';
import { withGridContext } from '@apps/firsthomecoach/src/pages/dashboard/components/with-grid';

import styles from '../../../../styles/widgets/featured-tools.module.scss';

const Component = React.memo(props => {
  const { title, subtitle, content, button } = props;

  const descendingSortedContent = content.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate));

  return (
    <BaseArticles title={title} subtitle={subtitle} content={descendingSortedContent} button={button} {...props} />
  );
});

const mapStateToProps = state => ({
  content: state.content.featuredTools,
  loading: state.content.loadingFeaturedTools
});

const Tools = compose(
  withWidgetController({ className: styles.container, id: 'featured-tools' }),
  withGridContext
)(Component);

export const FeaturedTools = compose(
  connect(mapStateToProps, { getFeaturedTools }),
  lifecycle({
    async componentDidMount() {
      const { getFeaturedTools } = this.props;

      await getFeaturedTools();
    }
  }),
  branch(({ loading }) => loading, renderNothing)
)(Tools);

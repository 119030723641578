import React from 'react';
import { compose } from 'recompose';
import { Grid, Column } from '../../../../components/layout/grid';
import { ButtonWithIcon } from '../../../../components/buttons/button-with-icon';
import { EditorialCard } from '../../../../components/editorial-card';
import { PurifiedHtmlContainer } from '../../../../components/layout/purified-html-container';
import { THeadline } from '../../../../components/template';
import { ARTICLE_NAME_TYPES, getCategoryNameByType } from '../../../../helpers/articles/article';
import { ARTICLES, BLOG } from '../../../../helpers/navigation';
import { ConfigService } from '../../../../services/config-service';
import styles from '../../../../styles/widgets/content-widget.module.scss';
import { withWidgetController } from '../../components/with-controller';
import { withGridContext } from '../../components/with-grid';
import { withWidgetTracking } from '../../hocs/with-widget-tracking';

export const Component = React.memo(({ content, title, subtitle, layoutContext, button }) => {
  const showCardImage = ConfigService.get('CONTENT.showCardImage', true);

  return (
    <>
      <THeadline title={title} subtitle={subtitle} className={styles.header} />
      <Grid className={styles.grid} fill>
        {content.map(
          ({
            type,
            excerpt,
            title,
            subTitle,
            imageUrl,
            minutesOfReading,
            slug,
            alternativeLink,
            cardFooterLabel,
            displayCondition
          }) => {
            const to = alternativeLink ?? `/${type !== ARTICLE_NAME_TYPES.ARTICLE ? BLOG : ARTICLES}/${slug}`;
            const readTime =
              type === ARTICLE_NAME_TYPES.ANNOUNCEMENT
                ? getCategoryNameByType(type)
                : `${minutesOfReading} min ${getCategoryNameByType(type)}`;

            return (
              <EditorialCard
                key={slug}
                titleAs='h3'
                to={to}
                title={title}
                imageUrl={showCardImage && imageUrl}
                cardFooterLabel={cardFooterLabel}
                readTime={readTime}
                displayCondition={displayCondition}
              >
                <PurifiedHtmlContainer html={excerpt || subTitle} />
              </EditorialCard>
            );
          }
        )}
      </Grid>
      {button && (
        <Column className={styles['button-container']}>
          <ButtonWithIcon to={button.to} className={styles.button}>
            {button.label}
          </ButtonWithIcon>
        </Column>
      )}
    </>
  );
});

export const Widget = compose(
  withWidgetController({ className: styles.container, id: 'content' }),
  withGridContext,
  withWidgetTracking
)(Component);

import React from 'react';
import { ConfigService } from '@apps/firsthomecoach/src/services/config-service';
import { Template } from '@apps/firsthomecoach/src/components/template';
import { Spacer } from '@apps/firsthomecoach/src/components/layout/spacers';
import { DashboardWidget } from '@apps/firsthomecoach/src/pages/dashboard/widgets';
import { Footer } from '@apps/firsthomecoach/src/components/layout/page-footer';
import { withDashboard } from '@apps/firsthomecoach/src/pages/dashboard/hocs/with-dashboard';
import { CARD_LOCATION_PAGE_TYPES } from '@apps/firsthomecoach/src/helpers/repeaters/constants';
import { PageHeader } from '@apps/firsthomecoach/src/components/layout/page-header/custom/vm';
import { createWidgetIdentifier } from '@apps/firsthomecoach/src/helpers/reducers/application';
import { dashboardWidgets } from './widgets';

const Component = ({ widgetsToShow, widgets }) => {
  const {
    DASHBOARD: { pageHeader, topSpacer, bottomSpacer }
  } = ConfigService.get();

  return (
    <>
      {pageHeader.isVisible && (
        <PageHeader
          image={pageHeader?.image}
          name={pageHeader?.name}
          heading={pageHeader?.title}
          category={pageHeader?.category}
          subheading={pageHeader?.subtitle}
        />
      )}
      <Template>
        {topSpacer && <Spacer {...topSpacer} />}
        {widgetsToShow.map(([name, widget], index) => (
          <DashboardWidget
            key={name}
            name={name}
            widgets={widgets}
            pageType={CARD_LOCATION_PAGE_TYPES.dashboard}
            widgetIdentifier={createWidgetIdentifier(name, index + 1)}
            order={index + 1}
            {...widget}
          />
        ))}
        {bottomSpacer && <Spacer {...bottomSpacer} />}
      </Template>
      <Footer pageType='dashboard' />
    </>
  );
};

export const Dashboard = withDashboard(dashboardWidgets)(Component);

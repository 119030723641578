export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const CONFIRM_SIGN_UP_REQUEST = 'CONFIRM_SIGN_UP_REQUEST';
export const CONFIRM_SIGN_UP_SUCCESS = 'CONFIRM_SIGN_UP_SUCCESS';
export const CONFIRM_SIGN_UP_FAILURE = 'CONFIRM_SIGN_UP_FAILURE';

export const RESEND_CODE_SIGN_UP_REQUEST = 'RESET_CODE_SIGN_UP_REQUEST';
export const RESEND_CODE_SIGN_UP_SUCCESS = 'RESET_CODE_SIGN_UP_SUCCESS';
export const RESEND_CODE_SIGN_UP_FAILURE = 'RESET_CODE_SIGN_UP_FAILURE';

export const RESET_CODE_REQUEST = 'RESET_CODE_REQUEST';
export const RESET_CODE_SUCCESS = 'RESET_CODE_SUCCESS';
export const RESET_CODE_FAILURE = 'RESET_CODE_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const SET_ACCOUNT_ERROR = 'SET_ACCOUNT_ERROR';

export const CHECK_EMAIL_AVAILABILITY_REQUEST = 'CHECK_EMAIL_AVAILABILITY_REQUEST';
export const CHECK_EMAIL_AVAILABILITY_SUCCESS = 'CHECK_EMAIL_AVAILABILITY_SUCCESS';
export const CHECK_EMAIL_AVAILABILITY_FAILURE = 'CHECK_EMAIL_AVAILABILITY_FAILURE';

export const CHECK_EMAIL_VALIDITY_REQUEST = 'CHECK_EMAIL_VALIDITY_REQUEST';
export const CHECK_EMAIL_VALIDITY_SUCCESS = 'CHECK_EMAIL_VALIDITY_SUCCESS';
export const CHECK_EMAIL_VALIDITY_FAILURE = 'CHECK_EMAIL_VALIDITY_FAILURE';

export const getCheckEmailTypesByFlow = flow => {
  switch (flow) {
    case 'available':
      return {
        REQUEST: CHECK_EMAIL_AVAILABILITY_REQUEST,
        SUCCESS: CHECK_EMAIL_AVAILABILITY_SUCCESS,
        FAILURE: CHECK_EMAIL_AVAILABILITY_FAILURE
      };
    case 'registered':
      return {
        REQUEST: CHECK_EMAIL_VALIDITY_REQUEST,
        SUCCESS: CHECK_EMAIL_VALIDITY_SUCCESS,
        FAILURE: CHECK_EMAIL_VALIDITY_FAILURE
      };
    default:
      return;
  }
};

export const GET_MARKETING_PREFERENCES_REQUEST = 'GET_MARKETING_PREFERENCES_REQUEST';
export const GET_MARKETING_PREFERENCES_SUCCESS = 'GET_MARKETING_PREFERENCES_SUCCESS';
export const GET_MARKETING_PREFERENCES_FAILURE = 'GET_MARKETING_PREFERENCES_FAILURE';

export const SET_MARKETING_PREFERENCES_REQUEST = 'SET_MARKETING_PREFERENCES_REQUEST';
export const SET_MARKETING_PREFERENCES_SUCCESS = 'SET_MARKETING_PREFERENCES_SUCCESS';
export const SET_MARKETING_PREFERENCES_FAILURE = 'SET_MARKETING_PREFERENCES_FAILURE';

export const UPDATE_MARKETING_PREFERENCES = 'UPDATE_MARKETING_PREFERENCES';

export const CREATE_SHADOW_USER_REQUEST = 'CREATE_SHADOW_USER_REQUEST';
export const CREATE_SHADOW_USER_SUCCESS = 'CREATE_SHADOW_USER_SUCCESS';
export const CREATE_SHADOW_USER_FAILURE = 'CREATE_SHADOW_USER_FAILURE';

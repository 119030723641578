import React from 'react';
import classnames from 'classnames';
import { branch, renderComponent } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { Grid, Column } from '../../../components/layout/grid';
import { Spacer } from '../../../components/layout/spacers';
import styles from '../../../styles/widgets/widget-control.module.scss';

const InnerGrid = ({ grid, children }) => <Grid {...grid}>{children}</Grid>;
const Fragment = ({ children }) => <>{children}</>;

const Content = branch(({ grid }) => !isNil(grid), renderComponent(InnerGrid))(Fragment);

export const withLayout = Component => {
  return React.memo(({ grid, edge, span, topSpacer, bottomSpacer, className, id, ...props }) => {
    return (
      <>
        {topSpacer && <Spacer {...topSpacer} />}
        <Column renderAs='section' className={classnames(styles.container, className)} edge={edge} span={span} id={id}>
          <Content grid={grid}>
            <Component {...props} />
          </Content>
        </Column>
        {bottomSpacer && <Spacer {...bottomSpacer} />}
      </>
    );
  });
};
